<script>
export default {
  components: {}
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <slot />
      </div>
      <svg class="editorial"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 24 150 28"
     preserveAspectRatio="none">
 <defs>
 <path id="gentle-wave"
 d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z" />
  </defs>
  <g class="parallax">
   <use xlink:href="#gentle-wave" x="50" y="0" fill="#4b8dfa"/>
   <use xlink:href="#gentle-wave" x="50" y="3" fill="#3f68ab"/>
   <use xlink:href="#gentle-wave" x="50" y="6" fill="#164ba1"/>  
      <use xlink:href="#gentle-wave" x="50" y="6" fill="#042963"/>  
  </g>
</svg>
<br clear="all">
<span id="line"></span>

    </div>
  </div>
</template>

<style scoped>
::selection {
  background-color: #D5BE88;
  color: white;
}

.parallax > use {
  animation: move-forever 12s linear infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
}
.parallax > use:nth-child(2) {
  animation-delay: -2s;
  animation-duration: 5s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 3s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 2s;
}
@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
.editorial {
  position: absolute;
  bottom:0;
  display: block;
  width: 100%;
  height:150px;
  max-height: 200vh;
  margin: 0;
}

body {
  background-color: #fff;
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
  padding: 9em 0 0 0;
}

.content {
  font-family: 'Lato',sans-serif;
  text-align: center;
  background-color: #2d55aa;
  text-align: center;
  min-height: 75vh;
  margin: -.1em 0 0 0;
  padding: 1em;
  color: #eee;
  font-size: 2em;
  font-weight: 300;
}

.content {
  font-size: 1em;
}

.content h1 {
  margin-top: -1.3em;
  font-weight: 100;
  font-size: 5em;
  user-select: none;
  cursor: default;
}

/*prevent many large-by-comparison ripples by shrinking the height*/
@media (max-width: 50em) {
  .content h1 {
    font-size: 12vmax;
  }

  .editorial {
    height: 17vw;
  }
}
h1 {
  outline: none;
}

body {
  background-color: #234;
}
#line{
  height:100px;
  width:10px;
  background-color:white;
  position:relative;
  top:0;
  display:block;
  
  &::before{
    content:' ';
  }
}
</style>